import React from "react"

const DatoCmsText = ({ text, WrapperComponent, ...props }) => {
  return (
    <WrapperComponent
      {...props}
      dangerouslySetInnerHTML={{
        __html: text
      }}
    />
  )
}

export default DatoCmsText
