import React from "react"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import * as S from "./styled"
import { urlFor } from "../../util/url"
import SVG from "react-inlinesvg"

const Header = ({ light, backOnly, menuOpen, setMenuOpen, sticky, onBack }) => {
  const data = useStaticQuery(graphql`
    {
      logo: datoCmsAsset(filename: { eq: "logo.svg" }) {
        id
        filename
        url
      }
      brands: allDatoCmsBrand {
        edges {
          node {
            __typename
            name
            slug
          }
        }
      }
    }
  `)

  return (
    <>
      <S.Container as="header" light={light} sticky={sticky}>
        {!backOnly && (
          <S.MenuWrapper>
            <div className="links">
              <Link to="/#what-we-do">What we do</Link>
              <br />
              <Link to="/#about-us">About us</Link>
            </div>
            <S.HamburgerWrapper
              className="icon"
              onClick={() => setMenuOpen(s => !s)}
            >
              <S.Hamburger cross={menuOpen} />

              <span>{menuOpen ? "Close" : "Menu"}</span>
            </S.HamburgerWrapper>
          </S.MenuWrapper>
        )}
        <S.LogoWrapper onClick={() => navigate("/")}>
          <SVG src={data.logo.url} style={{maxWidth: "170px", margin: "auto"}}>
            <img src={data.logo.url} style={{maxWidth: "170px", margin: "auto"}} />
          </SVG>
        </S.LogoWrapper>
        {!backOnly ? null : (
          <S.BackWrapper onClick={onBack}>Back</S.BackWrapper>
        )}
      </S.Container>
    </>
  )
}

export default Header
