import styled from "styled-components"
import device from "../util/devices"

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 10px;
  margin-left: 10px;
  margin-right: 10px;

  @media (${device.tablet}) {
    grid-column-gap: 20px;
  }

  @media (${device.desktop}) {
    grid-template-columns: repeat(12, 1fr);
  }
`

export default GridLayout
