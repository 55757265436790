import React from "react"
import GridLayout from "../../layout/GridLayout"
import device from "../../util/devices"
import styled, { css } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import DatoCmsText from "../DatoCmsText"

const Container = styled(GridLayout)`
  font-size: 13px;
  line-height: 20px;
  a {
    color: inherit;
    text-decoration: underline;
  }

  transition: color 550ms ease-out;

  ${props =>
    props.light &&
    css`
      color: white;
    `};

  ${props =>
    props.sticky
      ? css`
          position: fixed;
          bottom: 0;
          margin-bottom: 10px;
          left: 0;
          right: 0;
          @media (${device.desktop}) {
            margin-bottom: 20px;
          }
        `
      : css`
          padding-top: 30px;
          padding-bottom: 80px;
        `}

  > :first-child {
    grid-column: 1 / 3;

    @media (${device.desktop}) {
      grid-column: 2 / 4;
    }
  }

  > :nth-child(2) {
    display: none;
    @media (${device.tablet}) {
      display: block;
      grid-column: 3 / 5;
    }
    @media (${device.desktop}) {
      grid-column: 6 / 9;
    }
  }

  #social {
    grid-column: 6;
    @media (${device.desktop}) {
      grid-column: 11 / 13;
    }

    a {
      text-decoration: none;
    }
  }
`

const Address = styled.div`
  p {
    display: inline;
    margin: 0;
  }
  br {
    display: none;
  }
`

const Footer = ({ sticky, light }) => {
  const data = useStaticQuery(graphql`
    {
      contact: datoCmsContactDetail {
        email
        address
        phone
        socialMedia {
          url
          name
        }
      }
    }
  `)
  return (
    <Container as="footer" sticky={sticky} light={light}>
      <div>
        <a href={`mailto:${data.contact.email}`}>{data.contact.email}</a>
        <br /> {data.contact.phone}
      </div>
      <DatoCmsText
        WrapperComponent={Address}
        text={data.contact.address.replace(/<br\s*?\/?>/g, " ")}
      />
      <div id="social">
        {data.contact.socialMedia.map((sm, i) => (
          <React.Fragment key={i}>
            <a href={sm.url}>{sm.name}</a>
            <br />
          </React.Fragment>
        ))}
      </div>
    </Container>
  )
}

export default Footer
