import styled, { css } from "styled-components"
import GridLayout from "../../layout/GridLayout"
import device from "../../util/devices"

export const Container = styled(GridLayout)`
  padding-top: 10px;
  z-index: 30;
  ${props =>
    props.sticky &&
    css`
      position: sticky;
      top: 0;
    `}

  @media (${device.tablet}) {
    padding-top: 20px;
  }

  transition: color 500ms ease-out;
  ${props =>
    props.light &&
    css`
      color: white;
    `}
`

export const LogoWrapper = styled.div`
  grid-column: 2 / 6;
  margin: 0 auto;
  cursor: pointer;
  display: flex;

  @media (${device.desktop}) {
    grid-column: 6 / 8;
    display: block;
  }
`

export const MenuWrapper = styled.div`
  font-size: 13px;
  line-height: 20px;

  @media (${device.desktop}) {
    grid-column: 2;
  }

  .links {
    a {
      letter-spacing: 0.03em;
      grid-row: 1;
      grid-column: 1;
      color: inherit;
      text-decoration: none;
    }
    @media (${device.phone}) {
      display: none;
    }
  }

  .icon {
    @media (${device.tablet}) {
      display: none;
    }
  }
`

export const HamburgerWrapper = styled.div`
  padding-top: 10px;
  align-self: start;
  min-height: 25px;
  cursor: pointer;

  display: flex;
  align-items: start;

  span {
    font-size: 13px;
    line-height: 20px;

    letter-spacing: 0.03em;
    margin-left: 7px;
  }
`

const hamburgerRow = css`
  width: 13px;
  border-bottom: 1px solid #171717;
  transition: all 250ms ease-out;
`

export const Hamburger = styled.div`
  ${hamburgerRow};
  position: relative;
  margin-top: 3px;

  ${props =>
    props.cross &&
    css`
      border: none;
    `}

  &::before {
    content: "";
    width: 100%;
    ${hamburgerRow};
    position: absolute;
    top: 5px;
    ${props =>
      props.cross &&
      css`
        transform: rotate(45deg);
      `}
  }

  &::after {
    content: "";
    width: 100%;
    ${hamburgerRow};
    position: absolute;
    top: 10px;
    ${props =>
      props.cross &&
      css`
        transform: rotate(-45deg) translate(3px, -4px);
      `}
  }
`

export const BrandsWrapper = styled.div`
  grid-row: 1;
  grid-column: 6;
  padding-top: 10px;

  @media (${device.desktop}) {
    grid-column: 11 / 13;
    grid-row: 1;
  }
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.03em;

  a {
    color: #bfbfbf;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }

  div {
    max-height: 0;
    overflow: hidden;
    position: absolute;
    transition: all 250ms ease-in;
  }

  &:hover,
  &:focus {
    div {
      max-height: 150px;
    }
  }
`

export const BackWrapper = styled.div`
  grid-row: 1;
  grid-column: 6;
  padding-top: 10px;
  font-size: 13px;
  line-height: 20px;
  text-align: right;
  padding-right: 10px;
  cursor: pointer;

  letter-spacing: 0.03em;

  @media (${device.desktop}) {
    display: none;
  }
`
